/**
 *
 */
(function($) {
  "use strict";

  function isMobile() {
    return window.innerWidth <= 768;
  }

  function isMobilePhone() {
    return window.innerWidth <= 375;
  }

  /**
   * Permet d'ajouter ou supprimer les class dans le body et header
   *
   * @param body
   * @param header
  */
  function enableSticky(body, header) {
    body.addClass('sticky-header');
    header.addClass('sticky');
    header.removeClass('scroll-up');
  }

  /**
   * Permet d'ajouter ou supprimer les class dans le body et header
   *
   * @param body
   * @param header
  */
  function disableSticky(body, header) {
    body.removeClass('sticky-header');
    header.removeClass('sticky');
    header.addClass('scroll-up');
  }

  Drupal.behaviors.ExternalLink = {
    attach: function (context, settings) {
      var host = window.location.host.replace(/\./g, '\\.');
      var match = new RegExp('(^http(s)?:\\/\\/(?!' + host + '))|(.*\.pdf$)');
      $('a:not(.dialog)').once().each(function() {
        if (match.test(this.href) || $(this).hasClass('external')) {
          $(this).on('click', function(event) {
            window.open(this.href, '_blank');
            return false;
          });
        }
      });
    }
  };

  Drupal.behaviors.header = {
    attach: function(context, settings) {
      var behavior = this;

      behavior.cursor(context, settings);
      behavior.headerBoxes(context, settings);
      behavior.logoAnimations(context, settings);
      behavior.sticky(context, settings);

      if (!isMobile()) {
        return;
      }

      behavior.mobileMenu(context, settings);
      behavior.showSearch(context, settings);
    },

    cursor: function(context, settings) {
      // Cursor
      var outerDiv = $('ul'),
          outDim = outerDiv.offset(),
          $menu = $('#block-bordas-block-bordas-refonte-main-menu'),
          $menuFirstLevel = $menu.find('.level-0'),
          menulevel0hasChild = $('header .level-0 > .has-child'),
          body = $('body');

      $menu.append('<div class="cursor"></div>');
      var $cursor = $('.cursor');

      outDim.right = (outDim.left + outerDiv.width());
      outDim.bottom = (outDim.top + outerDiv.height());

      $(document).on('mousemove', function(e) {
        $cursor.offset({
          left: e.pageX - 8,
          top: e.pageY - 8
        });
      });

      $menuFirstLevel.on('mouseenter', function() {
        $cursor.addClass('visible');
      });

      $menuFirstLevel.on('mouseleave', function() {
        $cursor.removeClass('visible');
      });

      $menuFirstLevel.find('.menu-item').on('mouseenter', function() {
        var colorClass = $(this).attr('class').split(' ').filter(function(color) {
            return (color.match(/(^|\s)color-term-\S+/g) || []).join(' ');
        });

        removeCursorClass();
        $cursor.addClass('link-hover ' + colorClass);
      });

      $menuFirstLevel.find('li').on('mouseleave', function() {
        removeCursorClass();
      });

      function removeCursorClass() {
        $cursor.removeClass(function(index, className) {
          return (className.match(/(^|\s)color-term-\S+/g) || []).join(' ');
        });
      }

      // Add/remove gray overlay on showing/hidding the main menu
      menulevel0hasChild.on('mouseenter', function () {
        if(!isMobile()) {
          body.addClass('menu-open');
        }
        $(this).addClass('show-submenu');
      });

      menulevel0hasChild.on('mouseleave', function () {
        body.removeClass('menu-open');
        $('.has-child.show-submenu').removeClass('show-submenu');
      });

    },

    mobileMenu: function(context, settings) {
      var $mobileMenu = $('.menu-mobile');
      var $mainMenu = $('#block-bordas-block-bordas-refonte-main-menu');
      var $menuLevel0 = $mainMenu.find('.level-0 > .menu-item.has-child > a');
      var $menuLevel1 = $mainMenu.find('.level-1 > .menu-item.has-child > a');
      var $menuNoLink = $mainMenu.find('ul > .menu-item.has-child > .nolink');
      $menuLevel0.attr('data-click-state', 1);
      $menuLevel1.attr('data-click-state', 1);
      $menuNoLink.attr('data-click-state', 1);

      $mobileMenu.once().on('click', function() {
        $(this).toggleClass('open');
        $('body').toggleClass('menu-open');
        $mainMenu.toggleClass('show');
      });

      $menuLevel0.on('click', function(e) {
        e.stopPropagation();
        if ($(this).attr('data-click-state') == 1) {
          $(this).attr('data-click-state', 0);
          e.preventDefault();
          $menuLevel0.parent().removeClass('open-submenu');
          $(this).parent().addClass('open-submenu');
          $(this).parent().unbind('click');
        } else {
          $(this).attr('data-click-state', 1);
          return true;
        }
      });

      $menuNoLink.on('click', function(e) {
        e.stopPropagation();
        if ($(this).attr('data-click-state') == 1) {
          $(this).attr('data-click-state', 0);
          $menuNoLink.parent().removeClass('open-submenu');
          $(this).parent().addClass('open-submenu');
        } else {
          $(this).attr('data-click-state', 1);
          $(this).parent().removeClass('open-submenu');
        }
      });

      $menuLevel1.on('click', function(e) {
        e.stopPropagation();
        if ($(this).attr('data-click-state') == 1) {
          $(this).attr('data-click-state', 0);
          e.preventDefault();
          $menuLevel1.parent().removeClass('open-submenu');
          $(this).parent().addClass('open-submenu');
        } else {
          $(this).attr('data-click-state', 1);
          return true;
        }
      });
    },

    headerBoxes: function(context, settings) {
      var $button = $('.button-wrapper'),
          $content = $('.content'),
          body = $('body');

      $button.once().on('click', function() {
        var $this = $(this),
            $span = $this.find('span').not('.count'),
            $offState = $span.attr('data-off-state'),
            $onState = $span.attr('data-on-state');

        if ($this.parent().hasClass('open')) {
          $this.parent().removeClass('open');
          $('.block-bordas-block').removeClass('open');

          // Remove gray background
          body.removeClass('menu-open');
          $this.removeClass('menu-open');

          // Change text
          $span.text($onState);
        } else {
          // Close all open divs and change all text to 'state on'
          $content.removeClass('open');
          $('.block-bordas-block').removeClass('open');
          //$button.find('span').not('.count').text($onState);

          // Add gray background
          body.addClass('menu-open');
          $this.addClass('menu-open');

          // Open clicked div
          $this.parent().addClass('open');
          $this.parent().parent().addClass('open');

          // Change clicked text
          $span.text($offState);

          $button.each(function( index ) {
            var $parent = $(this).parent();
            if(!$parent.hasClass('open')) {
              $(this).find('span').last().text($(this).find('span').last().attr('data-on-state'));
            }
          });
        }

        $(document).on('click', function(e) {
          if (!$(e.target).is('.button-wrapper.menu-open, .button-wrapper.menu-open *, .block-bordas-block .content.open .wrapper *')) {
            $this.parent().removeClass('open');
            $('.block-bordas-block').removeClass('open');

            // Remove gray background
            body.removeClass('menu-open');
            $this.removeClass('menu-open');

            // Change text
            $span.text($onState);
          }
        });
      });
    },

    showSearch: function(context, settings) {
      var searchBlock = $('.block-bordas-search > .content');

      searchBlock.once().on('click', function() {
        $(this).toggleClass('show');
      }).children().on('click', function(e) {
        e.stopPropagation();
      });
    },

    logoAnimations: function(context, settings) {
      var logoBlock = $('#block-bordas-block-bordas-refonte-logo .content a');

      logoBlock.on('mouseover', function() {
        $(this).addClass('animate');
      });

      logoBlock.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function() {
        $(this).removeClass('animate');
      });
    },

    sticky: function (context, settings) {
      var body = $('body', context);
      var header = $('#header > .header-top', context);
      var headerHeight = header.outerHeight();

      var lastScrollTop = 0;
      $(window).scroll(function () {
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
          if (st > headerHeight) {
            enableSticky(body, header);
          } else {
            disableSticky(body, header);
          }
        } else {
          if (st > headerHeight) {
            enableSticky(body, header);
          } else {
            disableSticky(body, header);
          }
        }
        lastScrollTop = st;
      });

      // Display search form
      var searchForm = $('#block-bordas-search-search-form');

      searchForm.on('click', function() {
        $(this).addClass('show');

        $(this).css({
          'max-width': '470px',
          'margin-right': 'auto'
        });

        $(this).find('.container-inline').css({
          'opacity': 1,
          'transform': 'translateX(0%)'
        });
      });
    }
  };

  Drupal.behaviors.bodyPadding = {
    attach: function (context, settings) {
      var body = $('body', context);
      var header = $('#header .header-top', context);

      var bodyPaddingTop = 0;
      var headerTop = 0;

      $(window).on('load resize drupalToolbarOrientationChange drupalToolbarTabChange', function () {
        var headerHeight = header.outerHeight();
        var toolbarHeight = 0;

        if (body.hasClass('toolbar-fixed') || body.hasClass('toolbar-vertical')) {
          toolbarHeight = 39;

          if (body.hasClass('toolbar-tray-open') && body.hasClass('toolbar-horizontal')) {
            var activeToolbarTrayHeight = $('.toolbar-tray.is-active', context).outerHeight();
            toolbarHeight = 39 + activeToolbarTrayHeight;
          }
        }

        bodyPaddingTop = headerHeight + toolbarHeight;
        headerTop = toolbarHeight;

        body.css('padding-top', bodyPaddingTop);

        if (body.hasClass('admin-menu')) {
          header.css('top', headerTop + 30);
        } else {
          header.css('top', headerTop);
        }
      });
    }
  };

  Drupal.behaviors.slider = {
    attach: function(context, settings) {

      var $slider = $('.slider-wrapper > .item-list > ul:not(.slick-initialized)');

      if (!$slider.length) {
        return;
      }

      $slider.slick({
        dots: false,
        adaptiveHeight: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              arrows: false,
            }
          }
        ],
      });
    }
  };

  Drupal.behaviors.sectionTematique = {
    attach: function(context, settings) {

      var $sectionThematique = $('.entity-section-thematique');
      var $tematiqueLink = $sectionThematique.find('.tabs-wrapper a');
      var $tematiqueTab = $sectionThematique.find('.item-list ul:not(.tabs-wrapper)');

      if (!$sectionThematique.length) {
        return;
      }

      // Set default active tab
      $('.tabs-wrapper li:first-child > a').addClass('active');
      $('li:first-child').addClass('active');

      // Toggle active display
      $tematiqueLink.on('click', function(e) {
        e.preventDefault();

        // Remove all active classes
        $(this).parents('.entity-section-thematique').find('.active').removeClass('active');

        // Add class to link and section
        $(this).addClass('active');
        var id = $(this).attr('href');
        $tematiqueTab.find('li' + id).addClass('active');
      });

    }
  };

  Drupal.behaviors.footer = {
    attach: function(context, settings) {
      var $footerMenu = $('footer .level-0 li');

      $footerMenu.on('click', function() {
        $('footer li').removeClass('open');
        $(this).addClass('open');
      });

      // Override optanon links when Didomi is activated.
      var optanonLinks = $('footer .optanon-toggle-display', context);
      if (!optanonLinks.length) {
        return;
      }
      setTimeout(function() {
        if (typeof Didomi == "undefined") {
          return;
        }

        optanonLinks.each(function() {
          $(this).on('click', function() {
            Didomi.preferences.show();
            return false;
          });
        });
      }, 737);
    }
  };

  Drupal.behaviors.cta = {
    attach: function(context, settings) {
      $( ".entity-paragraphs-item-full" ).each(function( index ) {
        if($(this).hasClass('demi')){
          $(this).parent().addClass('demi');
        }
      });
    }
  };

  Drupal.behaviors.trouverOuvrage = {
    attach: function (context, settings) {
      $('.trouver-ouvrage-form .step-1 .form-select[name="etape_1"]').hide();
      $('.trouver-ouvrage-form .step-1 ul li').removeClass('active');
      $('.trouver-ouvrage-form.auto-submit .form-actions').hide();
      $('.trouver-ouvrage-form .form-actions [type="submit"]').attr('disabled', 'disabled');
      $('.trouver-ouvrage-form .step-2 .form-select', context).attr('disabled', 'disabled');

      $('.trouver-ouvrage-form .step-1', context).find('.item-list a').once('trouver-ouvrage-form').on('click', function() {
        var $form = $(this).parents('form');
        var $id = $(this).data('trouver-id');

        $(this).parents('ul').find('li').removeClass('active');
        $(this).parent().addClass('active');
        $(this).parents('.step-1').find('.form-select').val($id).change();

        if ($form.hasClass('auto-submit')) {
          $form.submit();
        }
        else {
          $form.find('.step-2 .form-select').removeAttr('disabled');
          $form.find('[type="submit"]').removeAttr('disabled');
        }
        return false;
      });
    }
  };

  Drupal.behaviors.ouvrageDialog = {
    attach: function(context, settings) {
      $('.dialog', context).click(function(event) {
        var width = $(this).data('width');
        var height = $(this).data('height');
        var title = $(this).data('title') ? $(this).data('title') : $(this).text();
        var $dialog = $('<div></div>')
          .html('<iframe id="ouvrage-dialog" style="border:0;width:100%;height:100%" src="' + $(this).attr('href') + '"/>')
          .dialog({
            autoOpen: false,
            modal: true,
            title: title,
            width: width + 36,
            height: height + 75,
            close: function() {
              $(this).dialog('destroy').remove();
            },
            closeText: 'Fermer',
          });
        $dialog.dialog('open').css('overflow', 'hidden');
        event.preventDefault();
        return false;
      });
    }
  };

  Drupal.behaviors.ouvrageSlider = {
    attach: function(context, settings) {
      var ouvrageSlider = $('.ouvrages-slider');

      if (!ouvrageSlider.length) {
        return;
      }

      $('.ouvrages-list').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: false,
              centerPadding: '0px',
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          }
        ],
      });
    }
  };

  Drupal.behaviors.newsletterPopin = {
    attach: function(context, settings) {

      var $newsletterContent = $('.popin_newsletter').html();

      $('.savoir-plus.colorbox').on('click', function(e) {
        e.preventDefault();

        $('body').append('<div class="lightbox lb-newsletter"><div>' + $newsletterContent + '</div></div>');
        $('body').addClass('lightbox-open');

        $('.lightbox .cross.close-popin').on('click', function(e) {
          e.preventDefault();

          $('body').removeClass('lightbox-open');
          $('.lb-newsletter').remove();
        });
      });
    }
  };

  Drupal.behaviors.print = {
    attach: function(context, settings) {

      $('.link-print').on('click', function(e) {
        e.preventDefault();
        window.print();
      });

    }
  };

  Drupal.behaviors.social = {
    attach: function(context, settings) {
      $('.social-sharing-items .link-social').once('social-sharing').on('click', function(e) {
        e.preventDefault();

        $(this).siblings('.list-social').toggleClass('show');
      });

      $('.social-link-facebook').on('click', function(e) {
        e.preventDefault();
        var urlOuvrage = $(this).attr('data-url');
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + urlOuvrage);
      });
      $('.social-link-twitter').on('click', function(e) {
        e.preventDefault();
        var urlOuvrage = $(this).attr('data-url');
        window.open("https://twitter.com/intent/tweet?url=" + urlOuvrage);
      });
      $('.social-link-mail').on('click', function(e) {
        e.preventDefault();
        var email = '';
        var subject = 'Partage de l\'ouvrage ' + $(this).attr('data-title');
        var emailBody = $(this).attr('data-url');
        window.location = 'mailto:' + email + '?subject=' + subject + '&body=' +   emailBody;
      });
    }
  };

  Drupal.behaviors.searchPage = {
    attach: function(context, settings) {
      var behavior = this;
      var searchPage = $('.page-recherche');

      if (!searchPage.length) {
        return;
      }

      behavior.openFilters(context, settings);
      behavior.sortItems(context, settings);

      if (isMobile()) {
        behavior.moveSearchresultText(context, settings);
      }
    },

    openFilters: function(context, settings) {
      var headingOpen = $('aside .block:not(.block-current-search) h2');

      // Set default open on desktop only
      if (!isMobile()) {
        $('.block-facetapi:nth-child(2) h2').addClass('show-filters');
        $('.block-facetapi:nth-child(3) h2').addClass('show-filters');
        $('.block-facetapi:nth-child(4) h2').addClass('show-filters');

        $('a.facetapi-active').closest('.block-facetapi').find('h2').addClass('show-filters');
      }

      headingOpen.once().on('click', function() {
        $(this).toggleClass('show-filters');
      });
    },

    sortItems: function(context, settings) {
      var sortBlock = $('.header .sort .item-list');

      sortBlock.find('.active').parent('li').addClass('active');

      sortBlock.once().on('click', function() {
        $(this).find('ul').toggleClass('show');
      });
    },

    moveSearchresultText: function(context, settings) {
      $('.header > .count').insertAfter('h1.title');
    }
  };

  Drupal.behaviors.mea = {
    attach: function(context, settings) {
      $( '.meas-wrapper ul li' ).each(function( index ) {
        var classes =  $(this).find('div').attr('class');
        $(this).addClass(classes);
      });
    }
  };

  Drupal.behaviors.backToTop = {
    attach: function (context, settings) {
      var backToTop = $('#back-top', context);
      backToTop.hide();

      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          backToTop.fadeIn();
        } else {
          backToTop.fadeOut();
        }
      });

      backToTop.find('a').click(function () {
        $('body, html').animate({
          scrollTop: 0
        }, 800);
        return false;
      });
    }
  };

  Drupal.behaviors.ouvrageSliderAuteur = {
    attach: function(context, settings) {
      var ouvrageAuteur = $('.ouvrage-auteur');

      if (!ouvrageAuteur.length) {
        return;
      }

      ouvrageAuteur.slick({
        slidesToShow: 4,
        dots: true,
        arrows: true,
        responsive: [{
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              slidesToShow: 1,
            }
          }
        ],
      });
    }
  };

  Drupal.behaviors.moveOuvrageTitle = {
    attach: function(context, settings) {
      var $produitImage = $('.group-ouvrage-left-wrapper .field-name-field-ouvrage-image, .group-ouvrage-left-wrapper .feuilleteur-ouvrage-wrapper');
      var $imageDescription = $('.group-ouvrage-right-wrapper');

      $(window).on('load', function() {
        if(isMobilePhone()) {
          $('.node-type-ouvrage .content .node-ouvrage').first().find($produitImage).prependTo($imageDescription);
        }
      });
    }
  };

  Drupal.behaviors.pages403 = {
    attach: function(context, settings) {
      var $text403 = $('.region-content .block-system .content:contains("êtes pas autorisé")');
      $(window).on('load', function() {
        $text403.addClass('notice-block');
      });
    }
  };

  Drupal.behaviors.disabledLink = {
    attach: function(context, settings) {
      $('a.disabled').on('click', function(e) {
        e.preventDefault();
      });
    }
  };

  Drupal.behaviors.addToCart = {
    attach: function(context, settings) {
      $('.commerce-add-to-cart').on('submit', function(e) {
        e.preventDefault();

        var form = $(this);
        var url = form.attr('action');

        // Add page overlay
        $('body').addClass('menu-open-header');

        // Add 'Add to car message'
        var message = $('<div class="message-add-cart"><span class="icon-add-to-cart"></span><span class="text"></span></div>');

        $.ajax({
          type: "POST",
          url: url,
          data: form.serialize(),
          success: function (data) {
            var $response = $(data);
            var messageTimeout = 3000;

            // Add icon
            var drupalMessage = $response.find('.messages');
            drupalMessage.find('.element-invisible').remove();
            if (drupalMessage.hasClass('status')) {
              message.addClass('success');
              // Replace the long default message (which has a long article
              // title) by this short one.
              message.find('.text').text(Drupal.t('Cet article a été ajouté à votre panier.'));
            }
            else {
              message.addClass('error');
              message.find('.text').text(drupalMessage.text());
              messageTimeout = 10000;
            }
            form.prepend(message);
            // Remove page overlay
            $('body').removeClass('menu-open-header');

            // Remove message
            setTimeout(function() {
              message.animate({
                opacity: 0,
                bottom: '100px',
              }, 800, function() {
                $(this).remove();
              });
            }, messageTimeout);

            // If error, the party ends. No animation.
            if (message.hasClass('error')) {
              return;
            }

            var count = $('#block-bordas-block-bordas-refonte-mon-panier .button-wrapper .count');
            if (count.length == 0) {
              $('#block-bordas-block-bordas-refonte-mon-panier .button-wrapper').prepend('<span class="count"></span>');
            }

            // Refresh count number
            var cartBlockCount = $response.find('#block-bordas-block-bordas-refonte-mon-panier .count').html();
            var newCount = $('#block-bordas-block-bordas-refonte-mon-panier .count')
                .html(cartBlockCount)
                .addClass('add-to-cart');
            newCount.parents('#block-bordas-block-bordas-refonte-mon-panier').css({
              'z-index': '200',
            });

            // Refresh items in the cart popup
            var cartBlockItems = $response.find('#block-bordas-block-bordas-refonte-mon-panier .wrapper').html();
            $('#block-bordas-block-bordas-refonte-mon-panier .wrapper').html(cartBlockItems);

            newCount.animate({
              'opacity': '1',
              'transform': 'translateY(-50%) scale(1)',
              'height': 'initial',
            }, 1000, function () {
              $(this).css({
                'transform': 'translateY(-150%)',
                // 'transform': 'translateY(0)',
                }).removeClass('add-to-cart');
                $(this).removeAttr('style');
            });

          },
          error: function() {
            // Add error icon
            message.addClass('error');
            message.find('span.text').text(Drupal.t('AJAX error'));
            form.prepend(message);
          }
        });

      });

    }
  };

  Drupal.behaviors.scrollToStep2 = {
    attach: function(context, settings) {
      $('.trouver-ouvrage-form .step-1 ul li > a').on('click', function(e) {
        $([document.documentElement, document.body]).animate({
          scrollTop: $('.trouver-ouvrage-form .step-2').offset().top
      }, 2000);
      });
    }
  };

  Drupal.behaviors.enableCheckbox = {
    attach: function(context, settings) {
      var commerceBlock = $('.group-wrapper-commerce', context);
      var checkbox = $('.mvp-adoptant-checkbox .form-checkbox', commerceBlock);
      var cartButton = $('.commerce-add-to-cart .form-submit', commerceBlock);

      if (cartButton.hasClass('disabled')) {
        cartButton.prop('disabled', true);
      }
      checkbox.prop('disabled', false);

      checkbox.once().on('click', function() {
        $(this).attr('checked', !$(this).attr('checked'));

        if($(this).attr('checked')) {
          cartButton.prop('disabled', false);
          } else {
          cartButton.prop('disabled', true);
        }
        cartButton.toggleClass('form-button-disabled');
      });
    }
  };

  Drupal.behaviors.infoTooltip = {
    attach: function(context, settings) {
      var infoIcon = $('.group-wrapper-commerce .title .ico-tooltip-info');

      if (!infoIcon.length) {
        return;
      }

      var iconPosition = $('.group-wrapper-commerce .title .ico-tooltip-info').position();
      var top = iconPosition.top - 115;
      var left = iconPosition.left - 108;

      var infoText = $('.group-wrapper-commerce .tooltip-info');

      infoIcon.hover(
        function() {
          infoText.css({
            'position': 'absolute',
            'left' : left,
            'top' : top,
            'display' : 'block',
          });
        }, function() {
          infoText.css({
            'display' : 'none',
          });
        }
      );
    }
  };

  Drupal.behaviors.addToFavoris = {
    attach: function(context, settings) {
      // Uses flag js
      $(document).bind('flagGlobalBeforeLinkUpdate', function(event, data) {
        addToFavorites();

        function addToFavorites() {
          var favorisButton = $('.flag-ouvrages-favoris').parent();
          var url = favorisButton.attr('action');

          // Add page overlay
          $('body').addClass('menu-open-header');
          favorisButton.css('position', 'relative');
          var count = $('#block-bordas-block-bordas-refonte-mes-favoris .button-wrapper .count', context);
          if (count.length == 0) {
            $('#block-bordas-block-bordas-refonte-mes-favoris .button-wrapper', context).prepend('<span class="count"></span>');
          }

          $.ajax({
            type: "POST",
            url: url,
            data: favorisButton.serialize(),
            success: function(data) {
              var $response = $(data);

              // Refresh favoris count number
              var favorisBlockCount = $response.find('#block-bordas-block-bordas-refonte-mes-favoris .count').html();
              var newCount = $('#block-bordas-block-bordas-refonte-mes-favoris .count', context)
                .html(favorisBlockCount)
                .addClass('favoris-effect');
              newCount.parents('#block-bordas-block-bordas-refonte-mes-favoris').css({
                'z-index': '200',
              });

              // Refresh items in the favoris popup
              var favorisBlockItems = $response.find('#block-bordas-block-bordas-refonte-mes-favoris .wrapper').html();
              $('#block-bordas-block-bordas-refonte-mes-favoris .wrapper').html(favorisBlockItems);

              setTimeout(function() {
                // Remove page overlay
                $('.flag-message').hide();
                $('body').removeClass('menu-open-header');
              }, 850);
              newCount.animate({
                'opacity': '1',
                'transform': 'translateY(-50%) scale(1)',
                'height': 'initial',
              }, 1000, function() {
                $(this).css({
                  'transform': 'translateY(-150%)',
                }).removeClass('favoris-effect');
                $(this).removeAttr('style');
              });

              // Call flag link again to ensure proper binding of events.
              Drupal.flagLink();
            },
            error: function() {
              // Add error icon
              var messageError = $('.flag-message');
              messageError.addClass('error');
            }
          });
        }
      });
    }
  };

  Drupal.behaviors.scrollToFacetTop = {
    attach: function(context, settings) {

      var voirLink = $('.page-recherche .region-sidebar-first a.facetapi-limit-link', context);
      voirLink.attr('data-click-state', 1);

      voirLink.on('click', function(e) {
        if ($(this).attr('data-click-state') == 1) {
          $(this).attr('data-click-state', 0);
          e.preventDefault();
          return false;
        } else {
          $(this).attr('data-click-state', 1);
          e.preventDefault();
          $([document.documentElement, document.body]).animate({
            scrollTop: $(this).parent().find('ul').offset().top - 180
          }, 2000);

          // Add a page overlay for a smoother experience
          $('body').addClass('menu-open-header');

          setTimeout(function () {
            // Remove page overlay
            $('body').removeClass('menu-open-header');
          }, 1500);
        }
      });
    }
  };

  Drupal.behaviors.noOneTrust = {
    attach: function (context, settings) {
      if (settings.site.env == 'dev') {
        setTimeout(function() {
          $('#optanon, .optanon-alert-box-wrapper').remove();
        }, 500);
      }
    }
  };

  // Test iframe mail catcher lerobert.
  // Resize iframe.
  window.addEventListener('message', function(e) {
    var iframe = $('.mailcatcher-iframe');

    if (e.data.hasOwnProperty("frameHeight")) {
      var heightNew =  "".concat(e.data.frameHeight + 30, "px");
      console.log(heightNew);
      iframe.css('height', heightNew);
    }
  });

  Drupal.behaviors.showPassword = {
    attach: function(context, settings) {
      // modal, page user/change-password
      var input_pass_modal = $(
        "#user-login-form .form-item-pass," +
        "#bordsso-change-password-form .form-item-password input," +
        "#bordsso-change-password-form .form-item-confirm-password input", context);
      // page /user
      var input_pass_page = $(
        "#form_account .form-item-pass input, " +
        "#form_account .form-item-password input, " +
        "#form_account .form-item-confirm-password input," +
        "#commerce-checkout-form-authentify .form-item-bordas-login-pass input", context);

      if (input_pass_modal.length > 0 ) {
        input_pass_modal.append('<span id="show_password" class="pwd_show"></span>');
      }
      if (input_pass_page.length > 0 ) {
        $('<span id="show_password" class="pwd_show"></span>').insertAfter(input_pass_page);
      }

      if ($("#show_password").length > 0) {
        $("#show_password, #bordsso-change-password-form #password_show").once().on('click', function () {
          var input = $(this).parent().find('input')[0];
          if(input.type === "password") {
            input.type = "text";
          }
          else {
            input.type = "password";
          }
        });
      }
    }
  };


  Drupal.behaviors.removeHash = {
    attach: function(context, settings) {

      // when each button is clicked
      $(document).on('click', '.anchor-complement-papier, .anchor-technique, .anchor-complement-numerique', function () {
        // set a short timeout before taking action
        // so as to allow hash to be set
        
        setTimeout(function() {
          // call removeHash function after set timeout
          removeHash();
        }, 5);
      });
    
      // removeHash function
      // uses HTML5 history API to manipulate the location bar
      function removeHash() {
        history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
      }
    }
  };

})(jQuery);
